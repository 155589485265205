import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import htmlDecode from "html-entities-decoder"



const PostCol2 = (props) => (
    <div className={ props.layout === "solo" ? "w-full md:w-1/2 pt-12 md:p-6 flex flex-col flex-grow flex-shrink md:max-w-md"  : "w-full md:w-1/2 pt-12 md:p-6 flex flex-col flex-grow flex-shrink"}>
        <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow-lg">
            <Link to={ `/${props.post.slug}/`} className="flex flex-wrap no-underline hover:no-underline">
                {
                    props.post.featuredImage
					?
                    <Img fluid={ props.post.featuredImage.imageFile.childImageSharp.fluid } alt={props.post.featuredImage.altText} className="h-full w-full rounded-t pb-6" />
                    :
                    null
                }
                <h2 className="w-full font-bold text-xl text-gray-900 px-6">{ htmlDecode(props.post.title)}</h2>
                <div className="text-gray-800 font-serif text-base px-6 mb-5" dangerouslySetInnerHTML={{__html: props.post.excerpt}} />
            </Link>
        </div>
        <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow-lg p-6">
            <div className="flex items-center justify-between">
                {/* <img className="w-8 h-8 rounded-full mr-4 avatar" data-tippy-content="Author Name"src={props.post.author.avatar.url} alt="Avatar of Author" /> */}
                <p className="text-gray-600-contr text-xs md:text-sm">{props.post.postReadTime.postReadTime} MIN READ</p>
            </div>
        </div>
    </div>
)

export default PostCol2
