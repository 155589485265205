import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import htmlDecode from "html-entities-decoder"


const LeadCard = (props) => (
    <div className="flex h-full bg-white rounded overflow-hidden shadow-lg">
        <Link to={ `/${props.post.slug}/`} className="flex flex-wrap no-underline hover:no-underline w-full">
            <div className="w-full md:w-2/3 rounded-t">	
                {
                    props.post.featuredImage
                    ?
                    <Img fluid={ props.post.featuredImage.node.imageFile.childImageSharp.fluid } alt={props.post.featuredImage.node.altText} className="h-full w-full shadow" />
                    :
                    null
                }
            </div>

            <div className="w-full md:w-1/3 flex flex-col flex-grow flex-shrink">
                <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow-lg">
                    <h2 className="w-full font-bold text-xl text-gray-900 px-6 pt-8">{ htmlDecode(props.post.title)}</h2>
                    <div className="text-gray-800 font-serif text-base px-6 mb-5" dangerouslySetInnerHTML={{__html: props.post.excerpt}} />
                </div>

                <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow-lg p-6">
                    <div className="flex items-center justify-between">
                        {/* <img className="w-8 h-8 rounded-full mr-4 avatar" data-tippy-content="Author Name" src={props.post.author.avatar.url} alt="Avatar of Author" /> */}
                        <p className="text-gray-600-contr text-xs md:text-sm">{ props.post.postReadTime.postReadTime } MIN READ</p>
                    </div>
                </div>
            </div>
        </Link>
    </div>
)

export default LeadCard
