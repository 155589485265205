import React from "react"
import { graphql, Link } from "gatsby"
import _ from 'lodash'

import Layout from '../components/layout'
// import Nav from "../components/UI/Nav"
import LeadCard from "../components/UI/LeadCard"
import PostCol2 from "../components/UI/PostCol2"
import PostCol3 from "../components/UI/PostCol3"
import SubscribeCol3 from "../components/UI/SubscribeCol3"
import SubscribeFull from "../components/UI/SubscribeFull"


const layout = (posts) => {
  if (posts.length === 2) {
    return (
      <React.Fragment>
        <PostCol2 post={posts[1].node} />
        <SubscribeCol3 />
      </React.Fragment>
    )
  } else if (posts.length === 3) {
    return (
      <React.Fragment>
        <PostCol3 post={posts[1].node} />
        <PostCol3 post={posts[2].node} />
        <SubscribeCol3 />
      </React.Fragment>
    )
  } else if (posts.length === 4) {
    return (
      <React.Fragment>
        <PostCol3 post={posts[1].node} />
        <PostCol3 post={posts[2].node} />
        <SubscribeCol3 />
        <PostCol2 post={posts[3].node} layout={"solo"} />

      </React.Fragment>
    )
  } else if (posts.length === 5) {
    return (
      <React.Fragment>
        <PostCol3 post={posts[1].node} />
        <PostCol3 post={posts[2].node} />
        <SubscribeCol3 />
        <PostCol2 post={posts[3].node} />
        <PostCol2 post={posts[4].node} />

      </React.Fragment>
    )
  } else if (posts.length === 6) {
    return (
      <React.Fragment>
        <PostCol3 post={posts[1].node} />
        <PostCol3 post={posts[2].node} />
        <SubscribeCol3 />
        <PostCol3 post={posts[3].node} />
        <PostCol3 post={posts[4].node} />
        <PostCol3 post={posts[5].node} />
      </React.Fragment>
    )
  }
  else if (posts.length === 7) {
    return (
      <React.Fragment>
        <PostCol3 post={posts[1].node} />
        <PostCol3 post={posts[2].node} />
        <SubscribeCol3 />
        <PostCol3 post={posts[3].node} />
        <PostCol3 post={posts[4].node} />
        <PostCol3 post={posts[5].node} />
      </React.Fragment>
    )
  }
}


function BlogCategory(props) {
  const {
    data: {
      wpgraphql: { categories, pageBy, category },
    },
  } = props;

  function constructMetaData(category, currentPage) {
    let payload = {
      title: category.seo.title + (currentPage !== 1 ? ` | Page ${currentPage}` : ""),
      description: category.seo.metaDesc,
      canonical: `https://blog.zvistra.gr/`,
      fbTitle: category.seo.opengraphTitle ? category.seo.opengraphTitle : category.seo.title,
      fbDescription: category.seo.opengraphDescription ? category.seo.opengraphDescription : category.seo.metaDesc,
      fbUrl: `https://blog.zvistra.gr/`,
      fbType: "website",
      locale: "el",
      image: category.seo.opengraphImage ? category.seo.opengraphImage.sourceUrl : category.categoryImage.categoryImage.sourceUrl
    }
    return payload
  }

  // function getCanonicalUrl(category) {
  //   console.log( category )
	// 	return `https://blog.zvistra.gr/${category.slug}/`;
	// }

  return (
    <Layout metaData={constructMetaData(category, props.pageContext.currentPage)} >
      <div className="container px-4 pb-12 md:px-0 max-w-6xl mx-auto">
        <h1 className="text-center text-4xl mt-8" style={{ color: "#23829a"}}>{category.categoryPageH1.categoryPageH1}</h1>
        <div className="mx-0 sm:mx-6 mt-12">
          {/* <Nav canonical={ getCanonicalUrl(category) } /> */}
          <LeadCard post={category.posts.edges[0].node} />
          <div className="flex flex-wrap justify-between pt-12">
            {layout(category.posts.edges)}
          </div>
        </div>

        <ul className="flex justify-center py-8">
          {props.pageContext.numberOfPages > 1 && (Array.from({ length: props.pageContext.numberOfPages }).map((page, index) => (
            <li key={index} className="mr-4"  >
              <Link
                to={index === 0 ? `/` : `/page/${index + 1}`}
                className={props.pageContext.currentPage === index + 1 ? "active-pagination-link py-2 px-4 pagination-link" : "py-2 px-4 pagination-link"}
              >
                {index + 1}
              </Link>
            </li>
          )))}
        </ul>
        <SubscribeFull />
      </div>
    </Layout>
  )
}

export default BlogCategory

export const pageQuery = graphql`
  query GET_CATEGORY($id: ID!, $startPoint: String!, $postsPerPage: Int!) {
    wpgraphql {
      categories {
				edges {
				  node {
					name
					description
					slug
					categoryImage{
					  categoryImage{
						altText
            sourceUrl
            
            imageFile {
							childImageSharp {
							  fluid{
								  base64
								  aspectRatio
								  src
								  srcSet
								  srcWebp
								  srcSetWebp
								  sizes
							  }
							}
						}
					  }
					}
          posts {
						edges {
						  node {
							id
						  }
						}
					}
				  }
				}
      }
      pageBy(uri: "home") {
				title
				categoriesHeaderGq {
					categoriesheader
        }
      }
      category(id: $id) {
        id
        name
        slug
        seo {
          title
          metaDesc
          opengraphDescription
          opengraphTitle
          opengraphImage {
            sourceUrl
          }
        }
        categoryImage{
          categoryImage{
            altText
            sourceUrl
          }
        }
        categoryPageH1 {
          categoryPageH1
        }
        posts(after: $startPoint, first: $postsPerPage) {
          edges {
            node {
              title
              excerpt
              slug
              date
              postReadTime {
                postReadTime
              }
              featuredImage {
                  node {
                    sourceUrl
                    altText
                    imageFile {
                        childImageSharp {
                            fluid{
                                base64
                                aspectRatio
                                src
                                srcSet
                                srcWebp
                                srcSetWebp
                                sizes
                            }
                        }
                    }
                }
              }
              categories{
                nodes{
                  name
                  slug
                  categoryImage{
                    categoryImage{
                      altText
                      sourceUrl
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
