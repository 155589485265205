import React from "react"
import { Helmet } from "react-helmet"


const SubscribeFull = (props) => (
    <div className=" md:mx-12 font-sans bg-white rounded my-8 p-4 md:p-24 text-center shadow-lg ">
        <form action="https://aliomis.us5.list-manage.com/subscribe/post?u=870351cb1c3b55bab6a520676&amp;id=177424c52d" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank" noValidate>
            <div id="mc_embed_signup_scroll">
                <h2 className="font-bold break-normal text-2xl md:text-4xl pb-4" style ={{color: "#23829a"}}>Εγγραφείτε στο blog μας</h2>
                <div className="max-w-xl mx-auto p-1 pr-0 block md:flex md:flex-wrap items-center">
                    <label htmlFor="mce-EMAIL" className="text-base my-4 text-white">
                        email
                    </label>
                    <input type="email" name="EMAIL" id="mce-EMAIL" placeholder="yourmail@example.com" className="md:w-1/2 w-full appearance-none rounded shadow p-3 text-gray-600-contr mr-2 focus:outline-none subs-input-border" />    
                    <div id="mce-responses" >
                        <div id="mce-error-response" style={{display:"none"}}></div>
                        <div id="mce-success-response" style={{display:"none"}}></div>
                    </div>
                    <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true"><input type="text" name="b_870351cb1c3b55bab6a520676_9b16ecd22d" tabIndex="-1" value="" /></div>
                    <input type="submit" name="subscribe" id="mc-embedded-subscribe" value="Εγγραφη" className="w-full md:w-1/3 mt-4 md:mt-0 block md:inline-block appearance-none text-white text-base font-semibold tracking-wider uppercase py-4 rounded shadow cursor-pointer bg-color" />
                </div>
            </div>
        </form>
    </div>
)

export default SubscribeFull
