import React from "react"

const SubscribeCol3 = (props) => (
    <div className="w-full md:w-1/3 flex flex-col flex-grow flex-shrink m-0 p-0 md:p-6 pt-12">
        <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow-lg flex items-center sub-col3">
            <form action="https://aliomis.us5.list-manage.com/subscribe/post?u=870351cb1c3b55bab6a520676&amp;id=177424c52d" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="text-center p-4 w-full text-white" target="_blank" noValidate>
                <h2 className="text-3xl font-bold my-4" >
					<span className="block">Γίνετε συνδρομητής στο</span>
					<span>blog της Zvistra</span>
				</h2>
                <label htmlFor="mce-EMAIL" className="text-base my-4 text-white">
                    Εγγραφείτε στο blog μας για να μαθαίνετε πρώτοι τις εξελίξεις στα λογιστικά των επιχειρήσεων και όχι μόνο.
                </label>
                <input type="email" name="EMAIL" id="mce-EMAIL" placeholder="yourmail@example.com" className=" w-full appearance-none rounded shadow p-3 text-gray-600-contr mr-2 focus:outline-none subs-input-border mt-4" />    
                <input type="submit" name="subscribe" id="mc-embedded-subscribe" value="Εγγραφη" className="border-white my-4 w-full flex-1 mt-4 block md:inline-block appearance-none bg-transparent text-white text-base font-semibold tracking-wider uppercase py-4 rounded cursor-pointer " />

                {/* <button type="submit" className="border-white my-4 w-full flex-1 mt-4 block md:inline-block appearance-none bg-transparent text-white text-base font-semibold tracking-wider uppercase py-4 rounded hover:bg-white hover:text-teal-600">Abonnez-vous</button> */}
            </form>
        </div>
    </div>
)

export default SubscribeCol3
